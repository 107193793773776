import React from "react";

import { Container, Row, Col, Card, Button, Modal } from "reactstrap";
import SponsorModal from "./SponsorModal.js";
import { Carousel } from "react-responsive-carousel";
// import ProfilePageHeader from "./Headers/ProfilePageHeaderSpeakers";

function chunkArray(myArray, chunk_size) {
  var index = 0;
  var arrayLength = myArray.length;
  var tempArray = [];

  for (index = 0; index < arrayLength; index += chunk_size) {
    const myChunk = myArray.slice(index, index + chunk_size);
    // Do something if you want with the group
    tempArray.push(myChunk);
  }

  return tempArray;
}

function Sponsor({ sponsors }) {
  const sponsors1 = chunkArray(sponsors, 3);
  const sponsors2 = chunkArray(sponsors, 1);
  const [open, toggleOpen] = React.useState({
    open: false,
    sponsor: {},
  });

  const updateModal = (e, sponsor) => {
    e.preventDefault();
    toggleOpen({ open: !open.open, sponsor: sponsor });
  };

  return (
    <>
      <div
        style={{
          backgroundColor: "#fff",
        }}
        className="section section-with-space"
        data-parallax={true}
      >
        <Container>
          <h1 className="text-700 text-center mb-4">2019 Partners</h1>
          <span className="d-none d-xl-block d-lg-block d-md-block">
            <Carousel
              showThumbs={false}
              infiniteLoop={true}
              autoPlay={true}
              showStatus={false}
              showIndicators={false}
              showArrows={true}
            >
              {sponsors1.map((data, index) => (
                <Row className="bg-white" key={index}>
                  {data.map((sponsor, index) => (
                    <Col lg={4} key={index}>
                      <Card style={{ borderRadius: 0 }}>
                        <Container fluid>
                          <Row>
                            <a
                              href="#sjs"
                              onClick={(e, name, text, image) =>
                                updateModal(e, sponsor)
                              }
                            >
                              <Col lg={12}>
                                <img
                                  src={require(`assets/sponsors/${sponsor.image}`)}
                                  alt={sponsor.name}
                                  width="100%"
                                />
                              </Col>
                              <Col
                                lg={12}
                                className="text-center"
                                style={{
                                  backgroundColor: "#f12868",
                                  padding: "1rem 0rem",
                                }}
                              >
                                <h5
                                  className="text-white"
                                  style={{ margin: 0, padding: 0 }}
                                >
                                  {sponsor.type}
                                </h5>
                              </Col>
                            </a>
                          </Row>
                        </Container>
                      </Card>
                    </Col>
                  ))}
                </Row>
              ))}
            </Carousel>
          </span>
          <span className="d-xl-none d-lg-none d-md-none d-sm-block d-xs-block">
            <Carousel
              showThumbs={false}
              infiniteLoop={true}
              autoPlay={true}
              showStatus={false}
              showIndicators={false}
              showArrows={true}
            >
              {sponsors2.map((data, index) => (
                <Row className="bg-white" key={index}>
                  {data.map((sponsor, index) => (
                    <Col lg={4} key={index}>
                      <Card style={{ borderRadius: 0 }} key={index}>
                        <Container fluid>
                          <Row>
                            <a
                              href="#sjs"
                              onClick={(e, name, text, image) =>
                                updateModal(e, sponsor)
                              }
                            >
                              <Col lg={12}>
                                <img
                                  src={require(`assets/sponsors/${sponsor.image}`)}
                                  alt={sponsor.name}
                                  width="100%"
                                />
                              </Col>
                              <Col
                                lg={12}
                                className="text-center"
                                style={{
                                  backgroundColor: "#f12868",
                                  padding: "1rem 0rem",
                                }}
                              >
                                <h4
                                  className="text-white"
                                  style={{ margin: 0, padding: 0 }}
                                >
                                  {sponsor.type}
                                </h4>
                              </Col>
                            </a>
                          </Row>
                        </Container>
                      </Card>
                    </Col>
                  ))}
                </Row>
              ))}
            </Carousel>
          </span>
          <Row>
            <Col lg={12} className={"text-center"}>
              <Button
                href="/register"
                className="rounded-0 navbar-item-custom text-400 text-white px-4"
                color="primary"
                size="lg"
              >
                <span
                  style={{
                    color: "#fff",
                    textTransform: "none",
                    fontWeight: 900,
                    fontSize: "20px",
                  }}
                >
                  Register Now
                </span>
              </Button>
            </Col>
          </Row>
        </Container>
      </div>

      <Modal isOpen={open.open} toggle={(e) => updateModal(e)} style={{}}>
        {/* <ProfilePageHeader
                    title={open.sponsor && open.sponsor.type ? `<b><h4>${open.sponsor.type}</h4></b>` : ""}
                /> */}

        <div className="section profile-content" style={{ paddingTop: "3rem" }}>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={(e) => updateModal(e)}
            style={{
              position: "absolute",
              top: 10,
              right: 10,
              color: "#888888",
            }}
          >
            <span aria-hidden={true}>×</span>
          </button>
          <SponsorModal sponsor={open.sponsor} />
        </div>
      </Modal>
    </>
  );
}

export default Sponsor;
