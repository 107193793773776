import React from "react";
import { Container, Button, Row, Col, Card } from "reactstrap";

function Agenda() {
  const size =
    document.body.clientWidth >= 1024 ||
    document.documentElement.clientWidth >= 1024
      ? true
      : false;
  return (
    <>
      <div
        style={{
          backgroundColor: "#0d1e67",
          backgroundImage: "url(" + require(`assets/images/bg3.png`) + ")",
          backgroundAttachment: "fixed",
        }}
        className="section section-with-space"
        data-parallax={true}
      >
        <Container>
          <Row className="justify-content-start">
            <Col md={10}>
              <div className="title">
                <h1 className="text-700 text-center text-white">Key Topics</h1>
                <p className="text-400 text-white">
                  The ECDCF 2021 forum will deliberate and discuss key trends
                  and practices that make a successful enterprise cloud
                  strategy.{" "}
                  {!size && (
                    <>
                      <br />
                      <br />
                    </>
                  )}
                  From cloud adoption that help accelerate IT modernization to
                  multi-cloud strategies leading to application portability,
                  from Cloud IaaS (Infrastructure as a Service) to distributed
                  cloud to help extend the service accessibility, the forum
                  through industry experts will help you and your business get
                  back on track with cloud acting as a catalyst for growth.
                </p>
              </div>
            </Col>
            <Col md={5}>
              <Card
                className="px-4 py-4 rounded-0"
                style={{ background: "rgba(0,0,0,0.5" }}
              >
                <ul className="text-white">
                  {day1.map((d) => (
                    <li>
                      <p className="text-400 mr-2">{d}</p>
                    </li>
                  ))}
                </ul>
              </Card>
            </Col>
            <Col md={5}>
              <Card
                className="px-4 py-4 rounded-0"
                style={{ background: "rgba(0,0,0,0.5" }}
              >
                <ul className="text-white">
                  {day2.map((d) => (
                    <li>
                      <p className="text-400 mr-2">{d}</p>
                    </li>
                  ))}
                </ul>
              </Card>
            </Col>
            <Col lg={10} className={"text-center mt-3"}>
              <Button
                href="/register"
                className="rounded-0 navbar-item-custom text-400 text-white px-4"
                color="primary"
                size="lg"
              >
                <span
                  style={{
                    color: "#fff",
                    textTransform: "none",
                    fontWeight: 900,
                    fontSize: "20px",
                  }}
                >
                  Register Now
                </span>
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

const day1 = [
  "Enterprise cloud strategies",
  "Public cloud adoption & PaaS",
  "Hybrid, Multi & Omni cloud environments",
  "Automation for cloud administration",
  "Hyper-scale data centers",
  "Compliance & Governance",
];

const day2 = [
  "Hyper converged Infrastructure",
  "Cloud as IaaS",
  "Data Accessibility & Visibility with Cloud",
  "AI & ML in Enterprise cloud & Data center",
  "Cloud migration for businesses",
  "Choosing the right System Integrators (SI’s)",
];

export default Agenda;
