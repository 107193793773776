import React from "react";

import { Container, Row, Col } from "reactstrap";
// import CountUp from 'react-countup';

import "react-circular-progressbar/dist/styles.css";

function AboutPoints() {
  return (
    <>
      <div
        className="section"
        style={{
          backgroundColor: "#fff",
          backgroundImage: "url(" + require(`assets/images/bg2.png`) + ")",
          backgroundAttachment: "fixed",
        }}
      >
        {/* <div className="overlay2 d-none d-xl-block d-lg-block " />
        <div className="overlayMedium d-none d-xl-none d-lg-none d-md-block" />
        <div className="overlayMobile d-xl-none d-lg-none d-md-none d-sm-block d-xs-block" /> */}
        <Container>
          <Row>
            <Col lg={6}></Col>
            <Col className="ml-auto mr-auto text-dark" md={6}>
              <h1 className="text-700 text-dark mb-4">
                Your reasons to attend
              </h1>
              <div className="mb-4">
                <h5 className="text-700 mt-0 text-g">
                  Develop a cloud first strategy
                </h5>
                <p className="text-400 mt-0">
                  With cloud first strategy becoming more important than ever,
                  learn how to work on your strategy and compare your existing
                  ones with the best in the industry.
                </p>
              </div>
              <div className="mb-4">
                <h5 className="text-700 mt-0 text-g">
                  Make your business resilient
                </h5>
                <p className="text-400 mt-0">
                  As confidence in enterprise cloud increases, understand how it
                  will help your business become more resilient and ensure
                  continuity even in times of uncertainty.
                </p>
              </div>
              <div className="mb-4">
                <h5 className="text-700 mt-0 text-g">
                  Bridge your skill gap on cloud
                </h5>
                <p className="text-400 mt-0">
                  The lack of skilled resources is a big reason that businesses
                  are not able to optimize their cloud programs, overcome this
                  handicap.
                </p>
              </div>
              <div className="mb-4">
                <h5 className="text-700 mt-0 text-g">
                  Evolving customers & Transforming businesses
                </h5>
                <p className="text-400 mt-0">
                  As customers take better, informed decisions and your business
                  processes transform, technology is the enabler helping you
                  stay ahead of the evolution curve.
                </p>
              </div>
              <div className="mb-4">
                <h5 className="text-700 mt-0 text-g">
                  Boost Customer Experience & Employee Productivity
                </h5>
                <p className="text-400 mt-0">
                  Empower your employees to work from anywhere with better
                  access to data and tools. Deliver a superior customer service,
                  sustained customer engagement for a more fruitful CX.
                </p>
              </div>

              <br />
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default AboutPoints;
