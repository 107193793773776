import React from "react";
import { Container, Row, Col } from "reactstrap";

function TextGrid() {
  let pageHeader = React.createRef();
  const size =
    document.body.clientWidth >= 1024 ||
    document.documentElement.clientWidth >= 1024
      ? true
      : false;
  return (
    <>
      <div
        style={{
          backgroundColor: "#fff",
        }}
        className="page-header"
        data-parallax={true}
        ref={pageHeader}
      >
        <Container>
          <Row>
            <Col md={6} className="align-self-center">
              <h1
                className="text-700 m-0 my-4"
                style={{
                  fontSize: size ? "4rem" : "3rem",
                  color: "#007bfc",
                }}
              >
                About
              </h1>
              <p className="text-400">
                As the world prepares to catch up with the digital acceleration
                one thing that reaffirmed its importance is enterprise cloud as
                a critical strategy for the future of businesses. In the Middle
                East, a cloud first approach has emerged as the key driver for
                the digital transformation.
              </p>
              <p
                className="text-700"
                style={{ color: "#007bfc", fontSize: "20px" }}
              >
                Data & Technology Review, region’s first title focused on data
                and analytics presents Enterprise Cloud & Data Center Forum
                (ECDCF) - a virtual event to create greater awareness on cloud,
                and its power to transform businesses.
              </p>
            </Col>
            {content.map((c) => (
              <Col md={6} key={c}>
                <img
                  src={require("assets/images/ECDC.png")}
                  width="100%"
                  alt=""
                />
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </>
  );
}

export default TextGrid;

const content = ["20NrTDS9o60"];
