import React from "react";

// core components
import Navbar from "../components/Navbar.js";
// import MobileNavbar from "../components/Navbars/MobileNavbar";
import LandingPageHeader from "components/Headers/LandingPageHeader.js";
import DemoFooter from "../components/Footer.js";
import TextGrid from "../components/TextGrid.js";

import AboutPoints from "../components/AboutPoints.js";
import KeyNotes from "../components/KeyNotes.js";

import News from "../components/News.js";
// import PriceCard from '../components/PriceCard.js';
// import ImageGrid from "../components/ImageGrid.js";
import Agenda from "../components/Agenda.js";
// import Venue from '../components/Venue';

import { news } from "./content";

function Home() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("profile-page");
    return function cleanup() {
      document.body.classList.remove("profile-page");
    };
  });
  return (
    <>
      <Navbar />
      <LandingPageHeader />
      <section id="about">
        <TextGrid />
      </section>
      <section id="agenda">
        <Agenda />
      </section>
      <section id="about">
        <AboutPoints />
      </section>

      <KeyNotes />
      <section id="news">
        <News news={news} />
      </section>
      <DemoFooter />
    </>
  );
}

export default Home;
