// reactstrap components
import { Container, Col, Row, Button } from "reactstrap";

import React from "react";

function LandingPageHeader({ home }) {
  const size =
    document.body.clientWidth >= 1024 ||
    document.documentElement.clientWidth >= 1024
      ? true
      : false;

  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 50 ||
        document.body.scrollTop > 50
      ) {
      } else if (
        document.documentElement.scrollTop < 300 ||
        document.body.scrollTop < 300
      ) {
      }
    };

    window.addEventListener("scroll", updateNavbarColor);

    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });

  return (
    <div
      style={{
        backgroudColor: "#fff",
        background:
          "url(" +
          require(`assets/images/${size ? "slider2.png" : "slider21.png"}`) +
          ")",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "cover",
      }}
    >
      <div
        className="page-header"
        style={{
          background: "transparent",
          backgroundImage:
            "url(" +
            require(`assets/images/${size ? "bg1.png" : "bg1-mobile.png"}`) +
            ")",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          paddingTop: size && "7rem",
        }}
        data-parallax={true}
      >
        {/* <div className="overlay-primary"></div> */}
        <Container>
          <Row className="align-items-center">
            <Col md={9} xs={12}>
              <h1
                className="text-700  text-dark m-0"
                style={{
                  fontSize: size ? "4rem" : "2.5rem",
                  lineHeight: size ? "4rem" : "2.5rem",
                }}
              >
                <span className="text-primary">Enterprise Cloud</span> & <br />
                Data Center Forum
              </h1>
              <h1
                className="text-700 text-dark m-0 mt-2"
                style={{ fontSize: size ? "1.75rem" : "1.25rem" }}
              >
                February 2020 | Virtual & On Demand Edition
              </h1>

              <Container fluid className="mt-3">
                <Row>
                  <Col lg={12} className="pt-3 px-0">
                    <Button
                      href="/sponsor"
                      className="rounded-0 navbar-item-custom text-400 text-white px-4"
                      color="primary"
                      size="lg"
                    >
                      <span
                        style={{
                          color: "#fff",
                          textTransform: "none",
                          fontWeight: 900,
                          fontSize: "20px",
                        }}
                      >
                        Sponsorship Details
                      </span>
                    </Button>{" "}
                  </Col>
                </Row>
              </Container>
            </Col>

            <Col md={6} xs={12} className="mt-4"></Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default LandingPageHeader;
