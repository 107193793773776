import React from "react";
import {
  Container,
  Collapse,
  Navbar,
  NavbarBrand,
  Nav,
  NavItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  NavLink,
} from "reactstrap";
import { Link } from "react-router-dom";
import classnames from "classnames";
import AnchorLink from "react-anchor-link-smooth-scroll";

function NavbarDiv({ home }) {
  const [navbarCollapse, setNavbarCollapse] = React.useState(false);
  const [scroll, setScroll] = React.useState(home ? true : false);

  const toggleNavbarCollapse = () => {
    setNavbarCollapse(!navbarCollapse);
    document.documentElement.classList.toggle("nav-open");
  };

  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 50 ||
        document.body.scrollTop > 50
      ) {
        setScroll(false);
      } else if (
        document.documentElement.scrollTop < 300 ||
        document.body.scrollTop < 300
      ) {
        setScroll(true);
      }
      if (!home) {
        setScroll(false);
      }
    };

    window.addEventListener("scroll", updateNavbarColor);

    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });

  return (
    <>
      <Navbar
        className={`fixed-top ${scroll && "navbar-transparent"}`}
        style={{ minHeight: "50px" }}
        color-on-scroll="300"
        expand="lg"
      >
        <Container fluid>
          <div className="navbar-translate">
            <NavbarBrand
              data-placement="bottom"
              to="/"
              target="_blank"
              className="p-0"
              tag={Link}
            >
              <img
                src={require(`assets/images/logo/3.png`)}
                width="100%"
                style={{ maxWidth: "300px" }}
                alt="logo"
              />
            </NavbarBrand>
            <button
              aria-expanded={navbarCollapse}
              className={classnames(
                "navbar-toggler text-right navbar-toggler",
                {
                  toggled: navbarCollapse,
                }
              )}
              onClick={toggleNavbarCollapse}
            >
              <span className="navbar-toggler-bar bar1 text-white" />
              <span className="navbar-toggler-bar bar2" />
              <span className="navbar-toggler-bar bar3" />
            </button>
          </div>
          <Collapse
            className="justify-content-end"
            navbar
            isOpen={navbarCollapse}
          >
            <Nav navbar>
              {menu.map((link, i) =>
                !link.items ? (
                  <NavItem key={i}>
                    {window.location.pathname === "/" && link.page === "/" ? (
                      <AnchorLink
                        offset="100"
                        href={`#${link.link}`}
                        onClick={toggleNavbarCollapse}
                        className="nav-link text-900"
                        style={{ color: scroll ? "#1d1960" : "#1d1960" }}
                      >
                        {link.name}
                      </AnchorLink>
                    ) : link.page ? (
                      <a
                        href={`/#${link.link}`}
                        className="nav-link  text-900"
                        style={{ color: scroll ? "#1d1960" : "#1d1960" }}
                      >
                        {link.name}
                      </a>
                    ) : (
                      <a
                        href={`${link.link}`}
                        className="nav-link  text-900"
                        style={{ color: scroll ? "#1d1960" : "#1d1960" }}
                      >
                        {link.name}
                      </a>
                    )}
                  </NavItem>
                ) : (
                  <UncontrolledDropdown nav inNavbar key={i}>
                    <DropdownToggle
                      nav
                      caret
                      className=" text-900"
                      style={{ color: scroll ? "#1d1960" : "#1d1960" }}
                    >
                      {link.name}
                    </DropdownToggle>
                    <DropdownMenu right>
                      {link.items.map((i) => (
                        <DropdownItem
                          href={i.link}
                          key={i.link}
                          className=" text-400"
                        >
                          {i.name}
                        </DropdownItem>
                      ))}
                    </DropdownMenu>
                  </UncontrolledDropdown>
                )
              )}
              <NavItem>
                <NavLink
                  to="/register"
                  tag={Link}
                  className="btn btn-primary rounded-0 navbar-item-custom text-400 text-white px-2"
                  style={{ opacity: "1" }}
                >
                  <span style={{ color: "#fff", textTransform: "none" }}>
                    Register Now
                  </span>
                </NavLink>
              </NavItem>
              <NavItem className="d-flex align-middle">
                {social.map((s) => (
                  <a
                    key={s.name}
                    className={`btn-primary text-900 pt-4 px-2`}
                    style={{
                      color: scroll ? "#1d1960" : "#1d1960",
                      backgroundColor: "transparent",
                    }}
                    href={s.link}
                  >
                    <i
                      className={`fa fa-${s.name}`}
                      style={{ fontSize: "1.5rem" }}
                    />
                  </a>
                ))}
              </NavItem>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default NavbarDiv;

const menu = [
  {
    name: "About",
    link: "about",
    page: "/",
    items: undefined,
    grid: 1,
  },
  {
    name: "Key Topics",
    link: "keytopics",
    page: "/",
    items: undefined,
    grid: 1,
  },
  // {
  //   name: "Speakers",
  //   link: "speakers",
  //   page: "/",
  //   items: undefined,
  //   grid: 1,
  // },
  // {
  //   name: "Partners",
  //   link: "partners",
  //   page: "/",
  //   items: undefined,
  //   grid: 1,
  // },
  {
    name: "News",
    link: "news",
    page: "/",
    items: undefined,
    grid: 1,
  },
];

const social = [
  {
    name: "facebook",
    link: "https://www.facebook.com/martechvibe/",
  },
  { name: "twitter", link: "https://twitter.com/martechvibe" },
  {
    name: "linkedin",
    link: "https://www.linkedin.com/company/martech-vibe-middle-east",
  },
  {
    name: "instagram",
    link: "https://www.instagram.com/martechvibe",
  },
];
