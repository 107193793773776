import React from "react";

import { Container, Row, Col, Card } from "reactstrap";

function News({ news }) {
  const size =
    document.body.clientWidth >= 1024 ||
    document.documentElement.clientWidth >= 1024
      ? true
      : false;
  return (
    <>
      <div
        style={{
          backgroundColor: "#F1F1F1",
        }}
        className="section section-with-space"
        data-parallax={true}
      >
        <Container>
          <Row>
            <Col xs={12} className="mb-5">
              <h1 className="text-700 text-center text-dark">News</h1>
            </Col>
          </Row>
          <Row>
            {news.map((n) => (
              <Col lg={6} className={"ml-auto mr-auto"}>
                <Card
                  className="rounded-0"
                  style={{ minHeight: size && "700px" }}
                >
                  <img
                    src={require(`assets/banners/${n.image}`)}
                    alt="meltwater"
                    className="rounded-0"
                    width="100%"
                  />
                  <div className="p-3">
                    <p className="text-dark text-700 ml-3 mb-3">{n.title}</p>
                    <p className="text-400">
                      <ul>
                        {n.points.map((p) => (
                          <li
                            key={p}
                            dangerouslySetInnerHTML={{ __html: p }}
                          ></li>
                        ))}
                      </ul>
                    </p>
                  </div>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </>
  );
}

export default News;
