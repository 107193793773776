import React from "react";
import {
  Button,
  Container,
  Row,
  Col,
  Input,
  Form,
  Alert,
  Spinner,
} from "reactstrap";
import { Redirect } from "react-router-dom";

function Register({ type }) {
  const [details, setDetails] = React.useState({
    type,
  });
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [redirect, setRedirect] = React.useState(false);

  const onChangeDetails = (e) => {
    setDetails({ ...details, [e.target.name]: e.target.value });
  };

  const onSubmitForm = (e) => {
    e.preventDefault();
    setLoading(true);
    setError(false);
    setSuccess(false);

    const reg = /[a-zA-Z0-9]*@[gmail|yahoo|hotmail]+\.[com|co.in]+/;
    if (reg.test(details.email.toLowerCase())) {
      setLoading(false);
      setError("Please enter your business email only");
      return null;
    }

    fetch("https://us-central1-ecdc-199b8.cloudfunctions.net/api/email", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(details),
    })
      .then((res) => {
        console.log(res);
        if (res.ok) return res;
        else throw new Error(res);
      })
      .then(() => {
        setLoading(false);
        setRedirect(true);
      })
      .catch(() => {
        setLoading(false);
        setError("Something went wrong please try again");
      });
  };
  let inputs = content;

  if (redirect) {
    return <Redirect to={`/thank-you/${type}`} />;
  }
  return (
    <div
      style={{
        paddingTop: "1rem",
        backgroundColor: "#0d1e67",

        overflow: "hidden",
      }}
    >
      <Container>
        <Row>
          <Col className="mx-auto mb-5 py-5" lg={8}>
            <Container className="my-auto text-main text-white text-400">
              <Form onSubmit={onSubmitForm} className="register-form">
                <Row className="justify-content-center">
                  {inputs.map((i) => (
                    <Col lg={6} className="my-2" key={i.name}>
                      <label>{i.placeholder}*</label>
                      <Input
                        placeholder={i.placeholder}
                        type={i.type}
                        name={i.name}
                        onChange={onChangeDetails}
                        required
                      />
                    </Col>
                  ))}

                  <Col lg={12} className="pt-2">
                    <p style={{ textTransform: "none" }}>
                      By clicking download/submit you agree to receive further
                      marketing information from Data & Technology Review on our
                      series of live and digital events. Please{" "}
                      <a href="/privacy-policy">
                        read our privacy policy here.
                      </a>
                    </p>
                  </Col>
                </Row>

                {loading ? (
                  <span>
                    <Spinner className="mt-3" color="warning" size="sm" />
                  </span>
                ) : (
                  <Button
                    type="submit"
                    disabled={loading}
                    className="rounded-0 text-400 text-white px-4 w-100"
                    color="primary"
                    size="lg"
                  >
                    <span
                      style={{
                        color: "#fff",
                        textTransform: "none",
                        fontWeight: 900,
                        fontSize: "20px",
                      }}
                    >
                      Submit
                    </span>
                  </Button>
                )}
              </Form>
            </Container>
            <CreateAlert success={success} error={error} />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

function CreateAlert({ success, error }) {
  const color = success ? "success" : "danger";
  const message = success ? success : error;
  if (!success && !error) {
    return "";
  } else {
    return (
      <Alert
        color={color}
        isOpen={true}
        fade={true}
        className="text-center"
        style={{ marginTop: "1rem" }}
      >
        {message}
      </Alert>
    );
  }
}

export default Register;

const content = [
  { id: 1, name: "first_name", type: "text", placeholder: "First Name" },
  { id: 2, name: "last_name", type: "text", placeholder: "Last Name" },
  { id: 3, name: "email", type: "text", placeholder: "Business Email" },
  { id: 4, name: "phone", type: "text", placeholder: "Mobile" },
  { id: 5, name: "company", type: "text", placeholder: "Company" },
  { id: 6, name: "job", type: "text", placeholder: "Job Title" },
  { id: 7, name: "country", type: "text", placeholder: "Country" },
];
