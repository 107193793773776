export const newspeakers = [
  {
    name: "Ashwin Gedam",
    link: "Ashwin-Gedam",
    title: "Global Head Marketing",
    company: "Xpress Money",
    text: [],
    image: "ashwin.jpg",
    company_image: undefined,
    topic: "",
  },
  {
    name: "Banali Malhotra",
    link: "Banali-Malhotra",
    title: "Director- Marketing",
    company: "RAKBANK",
    text: [],
    image: "banali.jpg",
    company_image: undefined,
    topic: "",
  },
  {
    name: "Daniel Gomez",
    link: "Daniel-Gomez",
    title: "Head of Digital – Fashion, Accessories and JV’s",
    company: "Chalhoub Group",
    text: [],
    image: "daniel.jpg",
    company_image: undefined,
    topic: "",
  },
  {
    name: "Firdaus Shariff",
    link: "Firdaus-Shariff",
    title: "VP Marketing and Digital Customer Experience ",
    company: "Schneider Electric ",
    text: [],
    image: "firdaus.jpg",
    company_image: undefined,
    topic: "",
  },
  {
    name: "Guil Rodrigues",
    link: "Guil-Rodrigues",
    title: "AVP Customer Experience and Design Lead ",
    company: "Payit by FAB",
    text: [],
    image: "guil.jpg",
    company_image: undefined,
    topic: "",
  },
  {
    name: "Piyush Chowhan",
    link: "Piyush-Chowhan",
    title: "Group CIO",
    company: "Lulu Group International",
    text: [],
    image: "piyush.jpg",
    company_image: undefined,
    topic: "",
  },
  {
    name: "Samir Talkhani",
    link: "Samir-Talkhani",
    title: "VP Digital Marketing and Sales",
    company: "Mashreq Bank",
    text: [],
    image: "samir.jpg",
    company_image: undefined,
    topic: "",
  },
  {
    name: "Youmna Borghol",
    link: "Youmna-Borghol",
    title: "Chief Data Officer",
    company: "Choueiri Group",
    text: [],
    image: "youmna.jpg",
    company_image: undefined,
    topic: "",
  },
];

export const speakers = [
  {
    name: "Scott Brinker",
    link: "Scott-Brinker",
    title: "Editor - Chief Marketing Technologist blog & VP Platform Ecosystem",
    company: "HubSpot",
    text: [
      "Scott Brinker writes the Chief Marketing Technologist blog, chiefmartec.com, where he has tracked the rise of marketing technology — and the profession of marketing technologists — for over 11 years. He is the program chair of the MarTech conference and author of the book Hacking Marketing.",
      "Scott also serves as the VP platform ecosystem at HubSpot, helping to grow and nurture the company's community of technology partners. Previously, he was the co-founder and CTO of ion interactive, a pioneer of interactive content marketing software to many of the world's leading brands.",
      "Scott has had articles published in Advertising Age, Adweek, Harvard Business Review, and TechCrunch. He holds degrees in computer science from Columbia University and Harvard University and an MBA from MIT. Connect with him on Twitter @chiefmartec.",
    ],
    image: "scott.jpg",
    company_image: "Chiefmartec_Hubspot.png",
    topic:
      "<b>HEADLINE PRESENTATION</b>: The New Rules of Marketing Technology & Operations",
  },
  {
    name: "Steve Lok",
    link: "Steve-Lok",
    title: "Global Head of Martech",
    company: "FRESHFIELDS BRUCKHAUS DERINGER",
    text: [
      "Steve recently completed a role as the Global Head of Martech at The Economist, where he led a storied content brand into a new era of growth and recognition with transformative techops strategy – his team affectionately referred to him as “The Plumber”. His team received numerous awards for their enablement of smart use of data in acquisition and engagement and in helping the team execute on their visions of the future – this winning combination of experience and talent helped The Economist take home a Cannes Lions, DMA Grand Prix, and dozens of awards recognising pioneering work in data and technology fused revenue generation and growth.",
      "He has now turned his attention to the business of law at Freshfields Bruckhaus Deringer, bringing martech to bear in B2B professional services as Global Head of Martech, working with the CMO, CTO, and CIO to ignite growth and bring about new business development dynamics. A dynamic and engaging speaker and mentor on martech, marketing technology, and thought leadership, Steve offers insights on how the confluence of tech, data, and content is monetized now and will develop into the future.",
    ],
    image: "steve.jpg",
    company_image: "FreshfieldsBruckhausDeringer.png",
    topic:
      "<b>1000 Different Covers</b>: Bespoke Experiences from Front to Back",
  },
  {
    name: "David Raab",
    link: "David-Raab",
    title: "Founder and CEO",
    company: "CDP Institute",
    text: [
      "David Raab is a marketing technology analyst and consultant who has helped hundreds of companies to find their right systems for their needs. He coined the term Customer Data Platform in 2013 and has been a leader in exploring and explaining how marketers can use CDPs to solve today’s most pressing marketing challenges.He is founder and CEO of the CDP Institute www.cdpinstitute.org.",
    ],
    image: "david.jpg",
    company_image: "CDPInstitute.png",
    topic:
      "<b>Keynote</b>: How Not to Pick Marketing Technology<br /><b>Workshop</b>: Making the most of Customer Data Platforms",
  },
  {
    name: "Steve Earl",
    link: "Steve-Earl",
    title: "Senior Director of Product Marketing, Customer Experience Cloud",
    company: "Oracle",
    text: [
      "Steve Earl is Senior Director of Product Marketing for Customer Experience Cloud where he focuses on the go-to-market strategy for CX products and solutions. Steve has almost 30 years in the technology industry with responsibilities spanning marketing, product strategy, software development, and strategic consulting. Over this time, Steve has worked with digital executives, digital marketers and analytics professionals at many medium to large sized companies worldwide. With a passion for data, he enjoys discovering new ways to improve marketing results through the application of data.",
    ],
    image: "Steve.png",
    company_image: "ORACLE.jpg",
    topic:
      "<b>Keynote</b>: Marketing in the Experience Economy<br/><b>Panel Discussion</b>: Is the new age CMO technologically aligned?",
  },

  {
    name: "Vikram Krishna",
    link: "Vikram-Krishna",
    title:
      "Executive Vice President, Head of Group Marketing & Customer Experience",
    company: "Emirates NBD",
    text: [
      "Vikram Krishna is the Executive Vice President, Head of Group Marketing & Customer Experience at Emirates NBD, with the mandate to position Emirates NBD as a leading banking brand, and enhance its customer experience capabilities. Krishna has successfully developed Emirates NBD into the No.1 banking brand in the UAE, with a brand value of USD 3.53 billion, in addition to making it one of the top 100 financial brands globally.",
      "Krishna oversees the three areas of Marketing, Customer Experience and Customer Lifecycle Management at the Emirates NBD Group level, continuously devising and implementing innovative strategies across all channels, to ensure a customer-centric approach and further grow the bank’s dominant market position. Krishna has also significantly raised Emirates NBD’s presence in the social media space through the successful launch and management of the bank’s social media platforms.",
      "During his tenure as Head of Group Marketing & Customer Experience, Emirates NBD has won several regional and international accolades, including recognition at Dubai Lynx, Effies MENA, Cannes and Midas, New York. Communicate magazine also listed Krishna in its Top 50 Power List for 2011 and 2012. Recognized for having raised the standard of marketing in the region, Krishna was named ‘GMR Marketer of the Year’ at the 2013 Effies MENA awards.",
      "Prior to his current role, Krishna was instrumental in the profitable turnaround of Emirates NBD’s Personal and Auto Loan business. He has also held the posts of Senior VP, Marketing-Retail Assets in HSBC, India, and VP & Head of Mortgages for North India in CitiFinancial. Krishna, who holds a Bachelor’s degree in Mathematical Statistics and an MBA in marketing, began his career with the advertising firm Lowe before moving to the banking industry.",
    ],
    image: "vikran.jpg",
    company_image: "EmiratesNBD.png",
    topic: "Building the marketing team of the future",
  },

  {
    name: "Kashif Siddiqui",
    link: "Kashif-Siddiqui",
    title: "CX Solutions Consultant",
    company: "SAP",
    text: [
      `Kashif is also passionate about facilitating design thinking and CX innovation workshops and hosting live external and internal events.  He has two Master's Degrees, an MBA in Marketing, and a Master of Public Health, from the Netherlands, and the  United States respectively, as well as a Bachelor's Degree from Rutgers University in New Jersey, USA. Apart from his practical work experience, Kashif has authored a number of papers on CRM and CX within multiple industries`,
    ],
    image: "Kashif.jpg",
    company_image: "SAP.png",
    topic: "",
  },
  {
    name: "Firdaus Shariff",
    link: "Firdaus-Shariff",
    title:
      "VP, Marketing, Communications & Digital Customer Experience – MEA Global Marketing",
    company: "Schneider Electric",
    text: [],
    image: "firdaus.jpg",
    company_image: "SchneiderElectric.png",
    topic: "",
  },
  // {
  // 	name: 'Melissa Jarvinen',
  // 	link: 'Melissa-Jarvinen',
  // 	title: 'Director of Sales & Marketing',
  // 	company: 'NOVO Cinemas',
  // 	text: [
  // 		'January 2018 marked the beginning of Melissa’s journey with Novo Cinemas as Sales and Marketing Director. She has been instrumental in shaping Novo’s online presence and developing strategies to put the customer experience at the heart of the organization. A highly skilled, innovative, tech-savvy and committed industry professional, Melissa used her 25 years of experience in marketing, advertising and hospitality to redefine marketing at Novo through customer journey mapping, personalization, marketing automation and engaging content. Having previously worked as Director of Marketing at the luxury FIVE, Palm Jumeirah hotel, Melissa brought a uniquely ‘five-star focus to Novo that has established the brand as the premium choice for movie lovers. Her result-driven digital strategies across social media, email marketing, public relations, sales, and website have proven successful in the cross-selling and upselling of multiple brands. ',
  // 		'A resident of the UAE for 17 years, Melissa has made Dubai her home and carved out a successful career working in an industry that allows her to indulge in her love for movies and latest technology all while leading a team of happy and dedicated employees. With a solid regional presence, she has successfully developed and launched multiple new brands. Open and engaging with all of Novo staff members, Melissa defines her work by the guiding principles of top-notch customer care, attention to detail, and a commitment to achieving the very best results.',
  // 		'Melissa’s success in a key leadership position has also helped Novo win 11 top industry awards within15 months. This is demonstrated in the key role she played in launching Novo’s new IMAX cinema in Dubai in early 2019. Dubai’s largest IMAX with Laser screen, located at IMG Worlds of Adventure, the state-of-the-art movie theatre delivers five-star customer services and gourmet F&B options.',
  // 		'Originally from South Africa, Melissa attained a Masters in Marketing Communications from the Middlesex University, Dubai. A life-long learner and avid reader keeps her thinking about the next step required to drive success and create engaged customer.',
  // 	],
  // 	image: 'melissa.jpg',
  // 	company_image: 'NovoCinemas.png',
  // 	topic: '<b>The Grand CMO PANEL DISCUSSION</b>: Is the new age CMO technologically aligned?',
  // },
  {
    name: "Dikdyuti Sen",
    link: "Dikdyuti-Sen",
    title: "Chief Marketing Officer",
    company: "Talabat",
    text: [
      "Dikdyuti Sen is the Chief Marketing Officer for two of brands in the Delivery Hero (ETR: DHER) portfolio, Talabat and Otlob. Operating in the GCC, Jordan, and Egypt, Talabat and Otlob seamlessly connect customers to their favorite restaurants and stores through online ordering. Previously, he has worked in companies such as Groupon and Google, gaining deep experience in marketing and measurement for pureplay online businesses.",
    ],
    image: "dikdyuti.jpg",
    company_image: "Talabat.png",
    topic:
      "<b>The Grand CMO PANEL DISCUSSION</b>: Is the new age CMO technologically aligned?",
  },
  {
    name: "Pablo Durante",
    link: "Pablo-Durante",
    title: "Chief Marketing Officer",
    company: "The Luxury Closet",
    text: [
      "Pablo is a marketing leader with 15+ years of experience, mainly in the digital and e-commerce. In his career he has covered several positions, working for companies with 10,000+ employees’ (such as eBay and Ogilvy), as well as in early-stage start-ups. Before moving to Dubai, he had lived and worked in six other cities in countries across the world (Milan, Paris, Zurich, Geneva, Barcelona and Madrid).",
      "Today he is serving as Chief Marketing Officer for The Luxury Closet, a Dubai-based marketplace for new & pre-loved luxury fashion, with offices in Dubai, Riyadh and Delhi. The Luxury Closet has raised so far c.a. $20m from leading regional investors.",
      "Steve lives for getting his hands dirty with transformative technology across disrupted industries with 26 years of experience across telecom, education, healthcare, publishing, media, professional services, and legal. Starting out with running BBSes in the early 90s before high school to more than a decade of experience as a website and Perl developer in the 90s, he can now cover everything from ISP to DSP and most acronyms in between. Co-founder of an early healthtech startup in the 00s led to a practice in agile project management and into the business of media and digital revenue growth in current times, Steve has a unique ability to fully understand the martech space from hub to spokes.",
    ],
    image: "pablo.jpg",
    company_image: "TheLuxuryCloset.png",
    topic:
      "<b>The Grand CMO PANEL DISCUSSION</b>: Is the new age CMO technologically aligned?",
  },
  {
    name: "Tirthankar Ganguly",
    link: "Tirthankar-Ganguly",
    title: "Chief Marketing Officer",
    company: "Sobha Realty",
    text: [],
    image: "tirthankar.jpg",
    company_image: "SobhaRealty.png",
    topic:
      "<b>The Grand CMO PANEL DISCUSSION</b>: Is the new age CMO technologically aligned?",
  },

  {
    name: "Avadhoot Revankar",
    link: "Avadhoot-Revankar",
    title: "Chief Growth Hacker & Product Evangelist",
    company: "Netcore Solutions",
    text: [
      "Avadhoot is Netcore’s Chief Growth Hacker & Product Evangelist, carries extensive knowledge in the MARTECH & Mobile Marketing space. Currently driving Growth for Netcore, he focuses on marketing and product hacks which can give us rapid revenue growth and dominant market share. He also has been the architect designing Innovative campaigns for not just Netcore but also for the brands associated with us.",
      "He comes with a strong blend of knowledge in Technology & Marketing which has led him to do more than 200+ digital marketing workshops and roundtable conferences, nationally & internationally. This young thought leader has been recognised among Top 5 Leaders for Email Experience Council & has won 25+ International Awards. As a person, he loves playing with data & football is his childhood passion.",
    ],
    image: "avadhoot.jpg",
    company_image: "Netcore.png",
    topic:
      "How Machine Learning Can Help Power Personalisation at Scale for 10x Growth",
  },
  {
    name: "Flavio Leoni",
    link: "Flavio-Leoni",
    title: "VP Global & Regional Sales MEA",
    company: "AccorHotels",
    text: [
      "With over 20 years of international experience across a range of multinational disciplines, including Hospitality, Financial Services, Mobility and Consumer Goods, Flavio’s career began with Unilever in Italy. Successive leadership role soon followed within Europcar and American Express, acquiring an extensive knowledge within these industries. Joined AccorHotels in 2008, Flavio has held progressive roles in Italy and Iberia Peninsula. Throughout the years, his scope has widened to include Sales, Distribution, Marketing, Loyalty in the Middle East & Egypt, and now focus on Sales for MEA.",
    ],
    image: "flavio.jpg",
    company_image: "AccorGroup.png",
    topic:
      "<b>Panel Discussion</b> – Building Better Customer Value Management (CVM) and Loyalty with Analytics",
  },
  {
    name: "Martin McDonald",
    link: "Martin-McDonald",
    title: "VP, EMEA",
    company: "Tealium",
    text: [
      "Martin McDonald is VP, EMEA for Tealium – the leading platform in real-time customer data solutions and enterprise tag management. With more than decades of experience in leading teams all over EMEA Martin is an expert in the MarTech und SaaS industry and keen to support companies with his expertise and knowledge regarding digital transformation, data challenges and customer data. Martin is passionate about helping businesses enter markets within the DACH region, East and South Europe. By combining local knowledge with existing relationships, Martin has a proven track record of success.",
    ],
    image: "martin.jpg",
    company_image: "tealium.png",
    topic:
      "<b>Panel Moderator</b>: Is the new age CMO technologically aligned?",
  },
  // {
  // 	name: 'Sianna Wu',
  // 	link: 'Sianna-Wu',
  // 	title: 'Director of Strategy and Digital Marketing',
  // 	company: 'The First Group',
  // 	text: [
  // 		'Sianna is a multi-disciplinary marketing professional with 11+ years experience in directing, executing & shaping omnichannel growth, strategy & marketing strategies across: Sales, ATL, BTL, social, PR, CRM, ORM & Customer Success, Adtech, Performance Advertising (buy & sell side) & branding partnerships for some of the world’s most loved brands & eCommerce players. She is currently the Director of Strategy & Digital Marketing at The First Group, based out of Dubai, UAE.',
  // 		'She has held commercial, operational, strategic and C-level digital marketing positions in various markets across MEAP at well-recognized fintech, real estate, eCommerce & tech companies with a successful track record in driving effective measures that impact P&L and growth.',
  // 	],
  // 	image: 'Sianna_Wu-The_First_Group.png',
  // 	company_image: 'TheFirstGroup.png',
  // 	topic: '<b>Panel Discussion</b> – Building Better Customer Value Management (CVM) and Loyalty with Analytics',
  // },
  {
    name: "Raghav Kumar",
    link: "Raghav-Kumar",
    title: "Head of Customer Marketing, Regional Marketing Lead",
    company: "CleverTap",
    text: [
      "Raghav is a technology marketer with 7+ years of experience that spans multiple functions of marketing. He’s worked with various levels of businesses spanning Fortune 500 companies and early-stage SaaS startups. Passionate about the areas of AI, ML and MarTech, he currently leads customer advocacy at CleverTap.",
    ],
    image: "Raghav.jpg",
    company_image: "cleverTap.png",
    topic:
      "The New Growth Mantra: How some of the biggest consumer brands in the world are built on user retention",
  },
  {
    name: "Matthew Sliedrecht",
    link: "Matthew-Sliedrecht",
    title: "Director - Marketing ",
    company: "Cleartrip",
    text: [
      "Matthew is the Director of Marketing at Cleartrip, one of the GCC’s largest and fastest growing online travel agencies. This includes overseeing all PR, above the line, performance marketing (paid search, paid social and remarketing), organic search marketing, and marketing automation.",
      "Matthew has over a decade of experience in marketing and has worked with global and local brands in telecommunications, travel and hospitality, financial services, automotive and FMCG. Matthew excels at transforming an organisation’s approach to marketing to maximise revenue, ROI & profit opportunities in a competitive and challenging e-Commerce market.",
      "Matthew has a passion for combining data with creative solutions to deliver outstanding results for brands while reaching consumers on an individual level.",
    ],
    image: "matthew.jpg",
    company_image: "Cleartrip.png",
    topic:
      "<b>Panel Discussion</b> – Building Better Customer Value Management (CVM) and Loyalty with Analytics",
  },
  {
    name: "Jitendra Kanojia",
    link: "Jitendra-Kanojia",
    title: "Head - Customer Analytics and Omnichannel",
    company: "FBB",
    text: [
      "Jitendra is the Head of the Omnichannel & Customer Engagement of fbb (Fashion @ Big Bazaar) at Future Retail Ltd. Over the years he has worked on analytics solutions for organizations such as Nike, Converse, Estee Lauder, Fossil, Target, LG and GSK.",
      "He is passionate about product innovation, customer experience and design. A seasoned professional, his core competencies are in Retail and CPG analytics, marketing programs, and partner relationship management. Jitendra also enjoys sharing his expertise with others, and is a guest faculty at some of the premier B schools.",
    ],
    image: "Jitendra_Kanojia-Future_Group.png",
    company_image: "fbb.jpg",
    topic:
      "<b>Case Study</b>: Leveraging Customer Data Platform (CDP) to make the most of your data",
  },
  {
    name: "Kunal Badiani",
    link: "Kunal-Badiani",
    title: "Regional Director MEA",
    company: "Tealium",
    text: [
      "Kunal is the Regional Director for Tealium managing the Middle East and Africa markets. Prior to Tealium, Kunal was managing the SaaS portfolio for IBM Watson Customer engagement. He has also had two stints with the Oracle Marketing Cloud in Australia and the UAE.He has worked on large scale, digital transformation initiatives in India, Australia, GCC and Africa. He has worked and lived in India, Canada and Australia and professionally has always enjoyed bridging the gap between strategy and execution using cutting edge technologyKunal has a MBA in Marketing and strategy from Melbourne Business School, Australia. He has completed his undergrad from University of Windsor in Canada.",
    ],
    image: "kunal.jpg",
    company_image: "TEA.png",
    topic: "Data Orchestration in the Enterprise",
  },
  {
    name: "Namrata Balwani",
    link: "Namrata-Balwani",
    title: "Marketing and Customer Experience Consultant",
    company: "Landmark Group",
    text: [
      "You’ll find Namrata at the intersection of business, marketing and technology. Namrata consults with corporates and startups on Marketing, Growth, Customer Experience and Digital Transformation. She has led digital transformation, digital marketing and customer analytics for the Middle East at the leading retailer, Landmark Group. Previously at OgilvyOne Worldwide, she led a regional unit working on digital strategies for global brands in Auto, Luxury, CPG and Financial Services.",
      "She took an entrepreneurial leap early into her career, co-founding Media2win, an independent, digital agency in India which was voted amongst the Best Digital Agencies by industry bodies. She’s also a believer in giving back to the industry through teaching the new generation. As Adjunct Faculty at Middlesex University, Dubai, she teaches digital marketing for Post Graduate programmes.			",
      "Namrata is a believer in harnessing the power of data and technology in marketing for revenue growth and in making evangelists of customers. Connect with her on Twitter @mysti or at https://www.namratabalwani.com/",
    ],
    image: "namrata.jpg",
    company_image: "nb.png",
    topic: "Harnessing data and analytics to personalise Customer Experience",
  },
  {
    name: "Michael Falorni",
    link: "Michael-Falorni",
    title: "Senior Manager",
    company: "Intercom",
    text: [
      "Michael has been working in the digital space for over 13 years. He started his career in South-East Asia where he worked for the Societe Generale in Vietnam for 1.5 years, was an Analyst for the venture DFJ and created a Digital Media Agency. Michael moved back to Europe in 2012 to work at Google, did his MBA in IE Business School in 2014 and managed the Continental Sales Teams in AdRoll for over 4 years. Michael joined Intercom last May and heads the Enterprise teams. Outside of work, Michael has a true passion for traveling, is married and a father of 1.",
    ],
    image: "Michael.jpg",
    company_image: "IC.png",
    topic: "How customer experience drives revenue",
  },

  {
    name: "Abbas Makhdum",
    link: "Abbas-Makhdum",
    title: "Global Director for CX Marketing",
    company: "Oracle",
    text: [
      "Abbas is the Director of Product Marketing for AI and emerging technologies at Oracle. With experience in SaaS-based marketing, artificial intelligence, analytics, customer experience platforms and personalization solutions, Abbas helps companies launch new markets, products, sales enablement and demand generation strategies that grow ARR.",
    ],
    image: "abbas.jpg",
    company_image: "ORACLE.jpg",
    topic: "",
  },

  {
    name: "Varij Saurabh",
    link: "Varij-Saurabh",
    title: "VP – Product Management",
    company: "Manthan Software",
    text: [
      "Varij focuses on creating innovative products that use analytics and deep customer insights to drive engagement, personalization and targeted marketing. He has helped consumer facing businesses such as retailers, restaurants and airlines in North America, Middle East and Asia leverage their data better, and achieve higher ROI for their marketing dollars.",
    ],
    image: "varij.jpg",
    company_image: "manthan.png",
    topic:
      "<b>Workshop</b>: From Aspiration to Reality – Fueling Marketing-led Growth",
  },
  {
    name: "Raj Badarinath",
    link: "Raj-Badarinath",
    title: "VP - Marketing &amp; Ecosystems",
    company: "RichRelevance",
    text: [
      "Raj helps marketers compete on memorable digital experiences, rather than on mere operational efficiency. With over 20 years of industry experience, Raj has held senior marketing, strategy, product and management positions across a variety of leading digital marketing and commerce providers.",
    ],
    image: "raj.jpg",
    company_image: "rr.jpg",
    topic:
      "<b>Workshop</b>: From Aspiration to Reality – Fueling Marketing-led Growth",
  },
  {
    name: "Pradyut Hande",
    link: "Pradyut-Hande",
    title: "Senior Growth Marketer & Product Evangelist",
    company: "Netcore Solutions",
    text: [
      "Pradyut is a mobile growth expert, marketing strategist, and author having worked in leading startups and MNCs across the SaaS, Consulting, and Banking industries. He currently spearheads Product Marketing at Netcore Smartech, enabling brands to deliver data-driven differentiated customer experiences powered by analytics, automation, and AI. He spends his free time reading, traveling, and being a Micro-Influencer on Instagram and YouTube.",
    ],
    image: "Pradyut_Hande-Netcore.png",
    company_image: "Netcore.png",
    topic: "<b>Workshop</b> - 5 Growth Hacks to Fuel App & Web Conversions",
  },
  {
    name: "Ravi Raman",
    link: "Ravi-Raman",
    title: "Associate Publisher, Wired Middle East",
    company: "Wired Middle East",
    text: [],
    image: "ravi.jpg",
    company_image: "Company.png",
    topic: "",
  },
];

export const sponsors = [
  {
    name: "Oracle",
    pageLink: "Oracle",
    type: "PRESENTING PARTNER",
    image: "ORACLE.png",
    text: [
      "The Oracle Cloud offers a complete suite of integrated applications for Sales, Service, Marketing, Human Resources, Finance, Supply Chain and Manufacturing, plus Highly Automated and Secure Generation 2 Infrastructure featuring the Oracle Autonomous Database. For more information about Oracle (NYSE: ORCL), please visit us at www.oracle.com",
    ],
    link: "https://www.oracle.com",
  },
  {
    name: "SAP",
    pageLink: "SAP",
    type: "CX PARTNER",
    alternateType: "CUSTOMER EXPERIENCE PARTNER",
    image: "SAP.png",
    text: [
      "SAP is the market leader in enterprise application software, helping companies of all sizes and in all industries run at their best: 77% of the world’s transaction revenue touches an SAP system. Our machine learning, Internet of Things (IoT), and advanced analytics technologies help turn customers’ businesses into intelligent enterprises. Our end-to-end suite of applications and services enables our customers to operate profitably, adapt continuously, and make a difference. With a global network of customers, partners, employees, and thought leaders, SAP helps the world run better and improves people’s lives. ",
    ],
    link: "https://www.sap.com/products/crm.html",
  },
  {
    name: "Manthan",
    pageLink: "Manthan",
    type: "GOLD SPONSOR",
    image: "MAN.png",
    text: [
      "Manthan Customer Marketing Platform empowers marketers to create long-lasting relationships with their customers. From the foundational customer data platform, to driving omnichannel hyper-personalized experiences, this is a complete offering for marketing led revenue growth. Manthan’s clients come from a range of B2C industries including retail, restaurants and online businesses, and have benefitted from higher marketing ROI and next-level of customer engagement.",
      "With over 220 customers in 23 countries, Manthan has been recognized for enabling the shortest path to profit, through its application of data sciences and AI. Headquartered in Bengaluru, Manthan has offices in San Francisco, Singapore and Dubai.",
    ],
    link: "https://www.manthan.com/product/customer-analytics",
  },
  {
    name: "Intercom",
    pageLink: "Intercom",
    type: "GOLD SPONSOR",
    image: "IC.png",
    text: [
      "Thanks to the internet, the way people communicate is drastically different than it was 10, even 5 years ago. Messengers like WhatsApp, have become the preferred mode of communication in every aspect of consumers’ lives, and now that switch is happening with businesses too.",
      "Intercom builds a suite of messaging-first products that all modern internet businesses can use to accelerate growth across the customer lifecycle, from acquisition, to engagement, and support.",
      "Intercom has raised $241M in venture funding and has over 450+ employees across its San Francisco headquarters and Dublin, London, Chicago, and Sydney offices. Today, Intercom is used by the world’s most forward thinking companies—84% of Y-Combinator companies that use a messaging or live chat tool use Intercom. Customers include larger companies like New Relic, Sotheby’s, and Shopify, and smaller companies like Airtable and Coda.",
    ],
    link: "https://www.intercom.com",
  },
  {
    name: "NetCore",
    pageLink: "NetCore",
    type: "MARTECH PARTNER",
    image: "NET.png",
    text: [
      "Netcore Solutions is a global Marketing Technology company that offers solutions to help brands and enterprises in customer acquisition, engagement, and retention. The first and leading Marketing Automation, Analytics and AI/ ML solutions provider in India, Netcore was established in 1997 by Rajesh Jain, an Internet pioneer. Netcore’s product suite includes Smartech and Pepipost. Smartech is an AI-powered growth marketing platform. Pepipost is an API based Email delivery platform.",
      "Netcore serves a strong base of 3000+ enterprises across industry verticals, like Thomas Cook, Go Air, Cleartrip, HDFC, Kotak, Axis Bank, ICICI Bank, MakeMyTrip, Lenskart, Swiggy, Myntra, BabyChakra, Dream11, Reliance, Vodafone, ITC, OLA, Pfizer along with International brands PizzaHut Malaysia, Malindo Air, Philippine Airlines, Seek Asia, Tokopedia, Blibli, Standard Chartered, FCMB, GT Bank and many more. Netcore with its innovative marketing technology, delivers 8+ Billion emails & 3+ Billion SMSs a month, creating 11+ Billion Customer Connects monthly and handles 30+ Billion Events a month. Netcore is headquartered in Mumbai, India with offices in SEA, USA, MEA and an employee base of 500+.",
    ],
    link: "https://www.netcore.in",
  },
  {
    name: "CleverTap",
    pageLink: "CleverTap",
    type: "SILVER SPONSOR",
    image: "CT.png",
    text: [
      "CleverTap helps consumer brands retain their users for life. It is a powerful mobile marketing solution that brings together user data from online and offline channels on one centralized platform. Every day, thousands of brands use CleverTap’s machine learning models to create differentiated customer engagement strategies that help marketers drive omnichannel growth. Build valuable customer relationships using actionable, real-time insights that help create amazing customer experiences.",
      "Over 8,000 global apps and websites, including Vodafone, Domino’s Pizza, DC Comics, Go-Jek, Cleartrip, BookMyShow, and DealsPlus trust CleverTap to create experiences that drive higher user lifetime value. To see how businesses of all sizes are building more meaningful customer relationships, visit our customers page.",
      "CleverTap operates out of San Francisco, New York, London, Singapore, Mumbai, and Bengaluru. CleverTap is backed by leading venture capital firms including Accel and Sequoia.",
    ],
    link: "https://www.clevertap.com",
  },
  {
    name: "Tealium",
    pageLink: "Tealium",
    type: "SILVER SPONSOR",
    image: "TEA.png",
    text: [
      "Tealium revolutionizes today’s digital businesses with a universal approach to customer data orchestration – spanning web, mobile, offline, and IoT devices. With the power to unify customer data into a single source of truth, Tealium offers a turnkey integration ecosystem supporting over 1,200 client-side and server-side vendors and technologies. The Tealium Universal Data Hub encompasses tag management, API hub, customer data platform, and data management solutions that enable organizations to leverage real-time data to create richer, more personalized digital experiences across every team, technology, and customer touchpoint. More than 850 businesses worldwide trust Tealium to power their customer data strategies.",
    ],
    link: "https://tealium.com/",
  },
  {
    name: "E-Cens",
    pageLink: "E-Cens",
    type: "EXHIBITOR",
    image: "ecens.png",
    text: [
      "e-Cens is an internationally recognized team of data scientists, analysts, technologists and strategists. We have over 15 years’ of experience working with leading companies in finance, travel and hospitality, publishing and e-commerce. Our clients trust us to advise and manage their data to achieve successful business outcomes. We look forward to helping you unlock the power of your data.",
    ],
    link: "https://e-cens.com/",
  },
  {
    name: "ObservePoint",
    pageLink: "ObservePoint",
    type: "EXHIBITOR",
    image: "OP.png",
    text: [
      `Great digital experiences start with accurate analytics data. We help teams establish an automated process that validates marketing technologies and restores confidence in your data-driven decisions. ObservePoint's automated platform helps cross-departmental teams plan, deploy, QA, and validate their MarTech and analytics strategy at scale, so they can achieve unparalleled data quality and gain complete confidence in their data.`,
      "<b>Why should you use ObservePoint?</b>",
      "Improve analytics testing efficiency by 83% <br />Increase digital analytics accuracy by 30% <br />Increase analytics team productivity by 75% <br />Scale testing frequency by more than 500%",
      "<b>Make better decisions with better data.</b> ObservePoint’s automated scanning tech will help you verify data was collected correctly so you can be confident in your marketing and analytics data.",
      "<b>Increase testing efficiency.</b> Instead of taking 25 hours to scan 1,000 pages, you could take 20-25 minutes to set up an automated, reusable ObservePoint audit that notifies you whenever an error occurs.",
      "<b>Ensure data protection and GDPR Compliance.</b> An ObservePoint scan can help you identify PII and any unauthorised or outdated technologies you need to remove from your site, protecting data from unauthorised use.",
    ],
    link: "https://www.observepoint.com/",
  },

  {
    name: "Exponea",
    pageLink: "Exponea",
    type: "NETWORKING PARTNER",
    image: "exponea.png",
    text: [
      `Exponea is an end-to-end Customer Data Platform that combines customer data analytics with omni-channel campaign execution, while cracking the unified Single Customer View. Through this, we have enabled marketers to understand their customers and immediately turn insights into actionable campaigns, ensuring an improved customer experience and increasing customer retention. Built on leading-edge technology, Exponea empowers marketers to deliver truly personalized experiences to their customers, at scale, through deeply embedded AI-powered capabilities, removing obstacles and leveraging intent to purchase both on your online store or outside of it. True to our vision, we work closely with our clients and mentor them all along the short path to success. We integrate quickly and enable the fast transfer of know-how which often results in a change of mindset and is a catalyst for a rapid, profitable growth of industry leading companies around the world. Exponea is ranked as the #1 Customer Data Platform on G2Crowd for 2019.`,
    ],
    link: "https://exponea.com/",
  },
  {
    name: "Insider",
    pageLink: "Insider",
    type: "NETWORKING PARTNER",
    image: "Insider.png",
    text: [
      "Insider Growth Management Platform (GMP) helps digital marketers drive growth across the funnel, from Acquisition to Activation, Retention, and Revenue.",
      "Leveraging real-time predictive segmentation powered by deep Artificial Intelligence and Machine Learning capabilities, the Growth Management Platform empowers marketers to deliver personalized journeys across the web, mobile web, mobile apps, and ad channels.",
      "Built on a unified data layer, GMP is easy to implement and simple to use, avoiding the need for complex integrations and dependency on IT teams. Insider simplifies the life of digital marketers and helps them drive growth for their brands, with zero marketing waste.",
      "Insider is a technology company with offices in London, Singapore, Tokyo, Hong Kong, Seoul, Sydney, Paris, Helsinki, Barcelona, Dubai, Moscow, Warsaw, Taipei, Jakarta, Istanbul, Kiev, Ho Chi Minh City, Bangkok, Ankara and Kuala Lumpur.",
      'Insider has been named a "Cool Vendor” in the report titled “Cool Vendors in Multichannel Marketing” by Gartner, Inc. and recognized as a Leader in the G2 Grid for Mobile Marketing in 2019. ',
    ],
    link: "https://useinsider.com/",
  },
  {
    name: "CMO Council",
    pageLink: "CMO-Council",
    type: "SUPPORTING ASSOCIATION",
    image: "CMO.png",
    text: [
      "The Chief Marketing Officer (CMO) Council is the only global network of executives specifically dedicated to high-level knowledge exchange, thought leadership and personal relationship building among senior corporate marketing leaders and brand decision-makers across a wide range of global industries. The CMO Council's 10,500-plus members control more than $500 billion in aggregated annual marketing expenditures and run complex, distributed marketing and sales operations worldwide. In total, the CMO Council and its strategic interest communities include more than 65,000 global executives in more than 110 countries covering multiple industries, segments and markets. Regional chapters and advisory boards are active in the Americas, Europe, Asia-Pacific, Middle East, India and Africa.",
    ],
    link: "https://www.cmocouncil.org",
  },
  {
    name: "IIB Council",
    pageLink: "IIB-Council",
    type: "SUPPORTING ASSOCIATION",
    image: "IIBC.png",
    text: [
      "IIB Council provides premium training and certification on futuristic technologies such as Digital Marketing, Smart Project Management and Blockchain courses that industry employers need today to transform the way they do business.",
    ],
    link: "https://www.iibcouncil.org",
  },
  {
    name: "Digital Analytics Association",
    pageLink: "Digital-Analytics-Association",
    type: "SUPPORTING ASSOCIATION",
    image: "DAA.png",
    text: [
      "The DAA is a not-for-profit, volunteer-powered association whose mission is to make analytics professionals more effective and valuable through professional development and community. Its vision is advancing the profession of using data to improve business. The DAA was founded as the Web Analytics Association in 2004. The organization has 5,000 members around the world, representing a broad spectrum of expertise.",
    ],
    link: "https://www.digitalanalyticsassociation.org",
  },
  {
    name: "MarTechVibe",
    pageLink: "MarTechVibe",
    type: "MEDIA PARTNER",
    image: "MTV.png",
    text: [
      "MarTech Vibe is the Middle East’s only online publication focused on marketing technology. It is at the intersection of where the greatest marketing ideas of today meet the tech solutions of tomorrow.",
      "We closely examine the MarTech world for lively, meaningful stories and analysis making it indispensable for new age marketers, thought leaders, and strategists. Our daily coverage includes news, industry trends, and analysis. We aim to empower marketers in the region with technology.",
      "In addition to the news coverage and thought leadership pieces, MarTech Vibe features articles written by several domain experts. They see MarTech Vibe as the only authoritative platform for them to share views.",
    ],
    link: "https://www.martechvibe.com/",
  },
  {
    name: "Think Marketing",
    pageLink: "Think-Marketing",
    type: "MEDIA PARTNER",
    image: "TM.png",
    text: [
      "Think Marketing Magazine is currently the number 1 most liked marketing community in MENA. Designed by marketers for marketers, the magazine has grown to create high-value content pieces for both brands and marketers to build campaigns, branding and strategies on.",
      "Working with several major companies in the region, Think Marketing Magazine has become a major player in providing marketers in the region and beyond with interesting insights, interviews and content. They have also partnered with major marketing events such as Cannes Lions Egypt, Dubai Lynx, Narrative PR Summit and more.",
      "Think Marketing Magazine believes in creating quality content for those that love informative data, and in providing helpful content to develop incisive and compelling strategies for the region’s marketers.",
    ],
    link: "https://www.thinkmarketingmagazine.com",
  },
  {
    name: "DataFloo",
    pageLink: "DataFloo",
    type: "MEDIA PARTNER",
    image: "DF.png",
    text: [
      "Datafloq offers information, insights and opportunities to drive innovation with big data, blockchain, artificial intelligence and other emerging technologies. It is the best place to find talent, receive leads or share your knowledge with millions of users.",
    ],
    link: "https://www.datafloq.com",
  },
];

const groupBy = (key) => (array) =>
  array.reduce((objectsByKeyValue, obj) => {
    const value = obj[key];
    objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
    return objectsByKeyValue;
  }, {});

const groupByType = groupBy("type");
export const sponsorsPage = groupByType(sponsors);

export const news = [
  {
    title: "Enterprise Cloud in the Middle East",
    points: [
      "<span class='text-700'>$6.5 billion</span> – Expected public cloud spending by 2024 <a href='https://www.cio.com/article/3408018/cloud-computing-in-the-middle-east-the-next-big-tech-market.html?upd=1603970698864'>(CIO.com)</a>",
      "<span class='text-700'>$4.8 billion</span> – Expected on premise enterprise data center spending in 2020 <a href='https://www.cio.com/article/3408018/cloud-computing-in-the-middle-east-the-next-big-tech-market.html?upd=1603970698864'>(CIO.com)</a>",
      "<span class='text-700'>500,000</span> – New jobs can be created by 2022 within the cloud domain across industries <a href='https://www.cio.com/article/3408018/cloud-computing-in-the-middle-east-the-next-big-tech-market.html?upd=1603970698864'>(CIO.com)</a>",
      "<span class='text-700'>66%</span> of business in the region have increased technology spending to recover from the global slowdown (Gulf Business)",
      "<span class='text-700'>64%</span> of IT leaders see cloud as their first priority while finalizing their IT budgets (Gulf Business)",
    ],
    image: "1.png",
    link: "",
  },
  {
    title: "What do IT leaders think about the Cloud?",
    points: [
      "<span class='text-700'>46%</span> of technology leaders plan to optimize their existing cloud infrastructure",
      "<span class='text-700'>38%</span> of IT leaders want to deploy new cloud to make savings",
      "<span class='text-700'>74%</span> of the It leaders have moved ahead in their plans for cloud implementation",
      "<span class='text-700'>51%</span> -increase in hybrid cloud adoption in three to five years <a href='https://www.intelligentcio.com/me/2020/03/10/uae-enterprises-plan-to-decrease-use-of-traditional-data-centres/'>(IntelligentCIO)</a>",
      "<span class='text-700'>70%</span> of IT professionals want to run all company applications on Cloud <a href='https://www.ameinfo.com/industry/tech-and-mobility/it-professionals-move-applications-to-cloud-veritas-report'>(Ameinfom)</a>",
    ],
    image: "2.jpg",
    link:
      "https://www.martechvibe.com/martech/martech-radar-list-of-active-marketing-technology-vendors-in-the-middle-east/",
  },
];
