import React from "react";

import { Container, Row, Col, Card } from "reactstrap";

const size =
  document.body.clientWidth >= 1024 ||
  document.documentElement.clientWidth >= 1024
    ? true
    : false;
const content = [
  {
    id: 1,
    values: [
      "Chief Executive Officer",
      "Chief Operations Officer",
      "Chief Digital Officer",
      "Chief Information Officer",
      "Chief Information Security Officer",
      "Chief Marketing Officer",
      "Vice-President/Director/General Manager/Manager – Operations, Information Technology, Technology, Digital, Digital Transformation, CRM, Cloud, eCommerce",
      "Managers/Specialists/Analysts – Digital Transformation, Information Technology, Business Excellence, Operations, CRM, Digital, Technology, eCommerce",
    ],
    color: "#422e6e",
  },
  {
    id: 2,
    title: "FOCUS INDUSTRIES",
    values: [
      "Banking & Finance",
      "Healthcare",
      "Automobile",
      "Retail & e-commerce",
      "Hospitality & Travel",
      "Real Estate",
      "Telecom",
      "Governments",
      "Transportation",
      "FMCG",
      "Media & Entertainment",
    ],
    color: "#f17f30",
  },
];

function KeyNotes() {
  return (
    <>
      <div
        className="section text-center"
        style={{
          backgroundColor: "#0d1e67",
        }}
      >
        <Container>
          <Row>
            <Col className="ml-auto mr-auto" md="8">
              <h1 className="text-700 text-center text-white mb-4">
                Who Should Attend
              </h1>
            </Col>
          </Row>

          <Row className="justify-content-center">
            {content.map((data, index) => (
              <Col md={6} xs={12} key={index} className="px-1 ">
                <Card
                  style={{
                    background: "rgba(0,0,0,0.5",
                    minHeight: size && "480px",
                    display: "flex",
                  }}
                >
                  <div className="text-white p-3">
                    <h4 className="text-left text-700 ml-3">{data.title}</h4>
                    <ul>
                      {data.values.map((c) => (
                        <li className="text-white text-left pt-2" key={c}>
                          <p className="m-0 text-white text-400">{c}</p>
                        </li>
                      ))}
                    </ul>
                  </div>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </>
  );
}

export default KeyNotes;
