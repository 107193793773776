/*!

=========================================================
* Paper Kit React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch } from "react-router-dom";

// styles
import "assets/css/bootstrap.min.css";

import "assets/css/paper-kit.min.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "assets/demo/demo.css";

import Home from "./views/home.js";
import RegisterPage from "./views/Register.js";
import ThankYouRegister from "./views/ThankYouRegister";
import PrivacyPolicy from "./views/PrivacyPolicy";

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route path="/" exact render={(props) => <Home {...props} />} />
      <Route
        path="/register"
        exact
        render={(props) => <RegisterPage {...props} type="REGISTER" title="Register"/>}
      />
      <Route
        path="/request-brochure"
        exact
        render={(props) => <RegisterPage {...props} type="BROCHURE" title="Download Brochure"/>}
      />
      <Route
        path="/sponsor"
        exact
        render={(props) => <RegisterPage {...props} type="SPONSOR" title="Sponsorship Details"/>}
      />

      <Route
        path="/privacy-policy"
        exact
        render={(props) => <PrivacyPolicy {...props} />}
      />
      <Route
        path="/thank-you/:id"
        exact
        render={(props) => <ThankYouRegister {...props} />}
      />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
