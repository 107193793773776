import React from 'react';
import { Container, Row, Col } from 'reactstrap';

function SponsorModal({ sponsor }) {
	if (sponsor && sponsor.image) {
		return (
			<>
				<Container>
					<div className="owner">
						<div
							className="avatar border"
							style={{
								backgroundColor: '#ffffff',
								maxWidth: '100%',
							}}
						>
							<a href={sponsor.link} target={'_blank'}>
								<img
									alt={sponsor.name}
									className="img-rounded img-no-padding img-responsive"
									src={require(`assets/sponsors/${sponsor.image}`)}
									style={{ maxWidth: '100%' }}
								/>
							</a>
						</div>
						<div className="name">
							<h4 className="title" style={{ marginBottom: '0' }}>
								{sponsor.name}
							</h4>
							<br />
						</div>
					</div>
					<Row>
						<Col className="" md="12">
							{sponsor.text.map((t, index) => (
								<p key={t} dangerouslySetInnerHTML={{ __html: t }}></p>
							))}
						</Col>
					</Row>
				</Container>
			</>
		);
	} else return '';
}

export default SponsorModal;
