import React from "react";
import { Row, Container, Col } from "reactstrap";

function DemoFooter() {
  return (
    <footer className="footer footer-black footer-white ">
      <Container fluid>
        <Row>
          <Col lg={1} xs={0} />
          <Col lg={4} style={{ paddingTop: "3rem" }}>
            <p>Organised By</p>
            <a href="/">
              <img src={require("assets/images/logo/31.png")} alt="vibe" />
            </a>
            <p className="text-400 text-dark">About Data & Technology Review</p>
            <p className="text-300 text-dark" style={{ fontSize: "14px" }}>
              <a href="http://DataTechnologyReview.com">
                DataTechnologyReview.com
              </a>{" "}
              (DTR) is a media platform that believes in the power of data to
              transform everything – from countries, economies, businesses to
              individuals. It is the essential source of knowledge that makes
              sense of a world being constantly disrupted. It takes a wide focus
              ranging from Data Science, AI & ML, Data Storage, Analytics, and
              Visualization to Policy.
            </p>
          </Col>
          <Col lg={3} className={"text-left"} style={{ padding: "3rem" }}>
            <Container>
              <Row>
                <Col xs={6}>
                  <p>
                    <a href="/" style={{ color: "#333333" }}>
                      Home
                    </a>
                  </p>
                  <p>
                    <a href="/#brandstory" style={{ color: "#333333" }}>
                      About
                    </a>
                  </p>
                  <p>
                    <a href="/#agenda" style={{ color: "#333333" }}>
                      Key Topics
                    </a>
                  </p>
                </Col>
                <Col xs={6}>
                  <p>
                    <a
                      href="https://www.martechvibe.com"
                      style={{ color: "#333333" }}
                    >
                      MarTechVibe
                    </a>
                  </p>

                  <p>
                    <a href="/privacy-policy" style={{ color: "#333333" }}>
                      Privacy-Policy
                    </a>
                  </p>
                </Col>
              </Row>
            </Container>
          </Col>
          <Col lg={4} style={{ padding: "3rem" }}>
            <p>Connect With Us</p>

            {social.map((s) => (
              <a
                key={s.name}
                className={`btn-primary text-900 pt-4 px-2`}
                style={{
                  color: "#1d1960",
                  backgroundColor: "transparent",
                }}
                href={s.link}
              >
                <i
                  className={`fa fa-${s.name}`}
                  style={{ fontSize: "1.5rem" }}
                />
              </a>
            ))}
            <p>
              For sponsorship enquires contact:{" "}
              <a href="mailto:sanjay@martechvibe.com">sanjay@martechvibe.com</a>{" "}
              or call +971 506775828
              <br />
            </p>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col xs={12} className={"text-center pt-10"}>
            <p>© 2021 Martechvibe All Rights Reserved.</p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

export default DemoFooter;

const social = [
  {
    name: "facebook",
    link: "https://www.facebook.com/martechvibe/",
  },
  { name: "twitter", link: "https://twitter.com/martechvibe" },
  {
    name: "linkedin",
    link: "https://www.linkedin.com/company/martech-vibe-middle-east",
  },
  {
    name: "instagram",
    link: "https://www.instagram.com/martechvibe",
  },
];
